import * as React from "react";
import { useContext, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { loggedInUser } from "../../apollo/cache";
import { theme } from "../../utils/theme";
import { AppBackButton, AppErrorText, AppText } from "../UI";

import {
  HeroImport,
  RulesTable,
  StepAdmins,
  StepCallPacing,
  StepCustomFields,
  StepEmailTemplates,
  StepFollowup,
  StepIntegrationV2,
  StepLeadRanking,
  StepLeadsV2,
  StepRepGoals,
  StepRestingRules,
  StepRevenue,
  StepSMSTemplates,
  StepTimesV2,
  StepTransferRulesV2,
  StepSMSSettings,
  StepUsersV2,
} from "../Segments/";

import { gql, useQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { useFlags } from "launchdarkly-react-client-sdk";
import ReactTooltip from "react-tooltip";
import { ModalContext } from "../../context";
import { info } from "../../images/NewDesign";
import { SystemConfigNavShape } from "../../types";
import { createSystemConfigNav } from "../../utils/misc";
import { CURRENT_IMPORT_POLL_INTERVAL } from "../../utils/variables";
import { CallRecordingStorageTab } from "../Segments/Onboarding/CallRecordingStorageTab";
import { ImportLeadActivity } from "../Segments/Onboarding/ImportLeadActivity";
import { StepRegisterDomain } from "../Segments/Onboarding/StepRegisterDomain";
import { CustomObjectTable } from "../Segments/Onboarding/custom-object/CustomObjectTable";
import { ObjectManager } from "../Segments/Onboarding/custom-object/ObjectManager";
import BookingWidget from "../Segments/Onboarding/widget/BookingWidget";
import CustomizeWidget from "../Segments/Onboarding/widget/CustomizeWidget";
import { PhoenixIcon } from "../UI/Phoenix";
import { ManageProducts } from "./ManageProducts";
import { MakeSaleConfiguration } from "./MakeSaleConfig/MakeSaleConfiguration";
import { RoutingRulesV3 } from "./RoutingRulesV3";
import { OPSIQFormsPage } from "./OPSIQForms/OPSIQFormsPage";
import { OPSIQFormDetail } from "./OPSIQForms/OPSIQFormDetail";
import { StepCallNoteTemplate } from "../Segments/Onboarding/StepCallNoteTemplate";
import { StepEmailSignature } from "../Segments/Onboarding/StepEmailSignature";
import { StepCustomizeLeadCard } from "../Segments/Onboarding/StepCustomizeLeadCard";

const FETCH_IN_PROGRESS_IMPORT = gql`
  query FetchInProgressLeadImport {
    fetchInProgressLeadImport {
      progress
      numerator
      denominator
      message
      filename
      id
    }
  }
`;

const FETCH_IN_PROGRESS_ACTIVITY_IMPORT = gql`
  query FetchInProgressLeadActivityImport {
    fetchInProgressLeadActivityImport {
      id
      denominator
      filename
      message
      numerator
      progress
      userid
      username
    }
  }
`;

interface ParamTypes {
  tab_name?: string;
  tab_type_id?: string;
}

const SystemConfiguration: React.FC = () => {
  const {
    registerDomain,
    leadIntegrationRelatedRecords,
    customObject,
    callRecordingStorage,
    callNoteTemplates,

    routingRulesV3,
    opsiqForm,

    emailSignature,
    leadCardConfig,
  } = useFlags();

  // const repLevelTransferRule = true;

  const SYSTEM_CONFIGURATION_TABS = useMemo(() => {
    return createSystemConfigNav({
      showFieldManager: !customObject,
      showCustomObject: !!customObject,
      showLeadRanking: true,
      showMakeSaleConfig: true,
      showRegisterDomain: !!registerDomain,
      showCallRecordingStorage: !!callRecordingStorage,
      showSMSSettings: true,
      showOpsiqForms: !!opsiqForm,
      showCallNoteTemplates: !!callNoteTemplates,
      emailSignature: !!emailSignature,
      leadCardConfig: !!leadCardConfig,
    });
  }, [registerDomain, callRecordingStorage, customObject, opsiqForm, emailSignature, leadCardConfig]);

  const { setShowTabNav, setTabNavData, setCurrentTab } = useContext(ModalContext);

  useEffect(() => {
    // re render the tooltip on system configuration tabs update
    ReactTooltip.rebuild();
  }, [SYSTEM_CONFIGURATION_TABS]);

  const getPageTitle = (tab_name: string | undefined, SYSTEM_CONFIGURATION_TABS: SystemConfigNavShape[]) => {
    // flatten the items array

    if (!tab_name) {
      return "";
    }
    const items = SYSTEM_CONFIGURATION_TABS?.map((tab: any) => {
      if (tab?.items?.length > 0) {
        return tab.items;
      } else {
        return tab;
      }
    }).flat();

    const tab = items.find((tab) => tab.tab_name === tab_name);

    // return the title of the tab or the tab_name if not found
    return tab?.title || tab_name;
  };

  const getPageToolTip = (tab_name: string | undefined, SYSTEM_CONFIGURATION_TABS: SystemConfigNavShape[]) => {
    // flatten the items array

    if (!tab_name) {
      return "N/A";
    }

    const items = SYSTEM_CONFIGURATION_TABS?.map((tab: any) => {
      if (tab?.items?.length > 0) {
        return tab.items;
      } else {
        return tab;
      }
    }).flat();

    const tab = items.find((tab) => tab.tab_name === tab_name);

    console.log("tab_name:", tab_name, tab, items);

    // return the title of the tab or the tab_name if not found
    return tab?.description || "N/A";
  };

  const [blinds, setBlinds] = useState(false);
  const [step, setStep] = useState(1);
  const [showBackButton, setShowBackButton] = useState(false);

  const { tab_name, tab_type_id } = useParams<ParamTypes>();

  const [hidePageTitle, setHidePageTitle] = useState(false);
  const togglePageTitle = () => setHidePageTitle(!hidePageTitle);

  const history = useHistory();

  useEffect(() => {
    setShowTabNav(true);
    setTabNavData(SYSTEM_CONFIGURATION_TABS as any);
    setCurrentTab(tab_name);
    if (!tab_name) {
      history.replace(SYSTEM_CONFIGURATION_TABS[0].items[0].path);
    }
    return () => {
      setShowTabNav(false);
      setTabNavData(undefined);
      setCurrentTab(undefined);
    };
  }, []);

  useEffect(() => {
    const pathNameArr = history.location.pathname.split("/");
    console.log(pathNameArr.length);
    if (pathNameArr.length > 3) {
      setShowBackButton(true);
    } else {
      setShowBackButton(false);
    }
  }, [history.location.pathname]);

  const { data: dataCurrentImport, loading: loadingCurrentImport, error: errorCurrentImport } = useQuery(
    FETCH_IN_PROGRESS_IMPORT,
    {
      fetchPolicy: "network-only",
      skip: tab_name !== "import-leads",
      pollInterval: CURRENT_IMPORT_POLL_INTERVAL,
      onError({ message, name }) {
        // Sentry.captureEvent({
        //   message: `${name} GraphQL Error: ${message}`,
        // });
        console.log(`Error in ${name}: `, message);
      },
    },
  );
  const {
    data: dataCurrentActivityImport,
    loading: loadingCurrentActivityImport,
    error: errorCurrentActivityImport,
  } = useQuery(FETCH_IN_PROGRESS_ACTIVITY_IMPORT, {
    fetchPolicy: "network-only",
    skip: tab_name !== "import-activity",
    pollInterval: CURRENT_IMPORT_POLL_INTERVAL,
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  if (tab_name === "email-settings" && emailSignature) {
    return (
      <Sentry.ErrorBoundary fallback={"An error has occured at Admin Onboarding"}>
        <StepEmailSignature step={step} blinds={blinds} setBlinds={setBlinds} />
      </Sentry.ErrorBoundary>
    );
  }

  if (tab_name === "email-templates") {
    return (
      <Sentry.ErrorBoundary fallback={"An error has occured at Admin Onboarding"}>
        <StepEmailTemplates step={step} blinds={blinds} setBlinds={setBlinds} />
      </Sentry.ErrorBoundary>
    );
  }

  if (tab_name === "sms-templates") {
    return (
      <Sentry.ErrorBoundary fallback={"An error has occured at Admin Onboarding"}>
        <StepSMSTemplates step={step} blinds={blinds} setBlinds={setBlinds} />
      </Sentry.ErrorBoundary>
    );
  }

  if (tab_name === "call-notes-template") {
    return (
      <Sentry.ErrorBoundary fallback={"An error has occured at Admin Onboarding"}>
        <StepCallNoteTemplate />
      </Sentry.ErrorBoundary>
    );
  }

  if (tab_name === "transfer-rules") {
    return (
      <Sentry.ErrorBoundary fallback={"An error has occured at Admin Onboarding"}>
        <StepTransferRulesV2
          step={step}
          blinds={blinds}
          setBlinds={setBlinds}
          tooltipText={getPageToolTip(tab_name, SYSTEM_CONFIGURATION_TABS)}
        />
      </Sentry.ErrorBoundary>
    );
  }

  if (tab_name === "scheduling") {
    return (
      <Sentry.ErrorBoundary fallback={"An error has occured at Admin Onboarding"}>
        <StepTimesV2
          step={step}
          blinds={blinds}
          setBlinds={setBlinds}
          tooltipText={getPageToolTip(tab_name, SYSTEM_CONFIGURATION_TABS)}
        />
      </Sentry.ErrorBoundary>
    );
  }

  if (tab_name === "register-domain" && registerDomain) {
    return (
      <Sentry.ErrorBoundary fallback={"An error has occured at Admin Onboarding"}>
        <StepRegisterDomain step={step} blinds={blinds} setBlinds={setBlinds} />
      </Sentry.ErrorBoundary>
    );
  }

  if (tab_name === "lead-ranking") {
    return (
      <Sentry.ErrorBoundary fallback={"An error has occured at Admin Onboarding"}>
        <StepLeadRanking step={step} blinds={blinds} setBlinds={setBlinds} />
      </Sentry.ErrorBoundary>
    );
  }

  if (tab_name === "make-sale-config") {
    return (
      <Sentry.ErrorBoundary fallback={"An error has occured at Admin Onboarding"}>
        <MakeSaleConfiguration />
      </Sentry.ErrorBoundary>
    );
  }

  if (tab_name === "manage-products") {
    return (
      <Sentry.ErrorBoundary fallback={"An error has occured at Admin Onboarding"}>
        <ManageProducts />
      </Sentry.ErrorBoundary>
    );
  }

  if (tab_name === "routing-rules" && !!routingRulesV3) {
    return (
      <Sentry.ErrorBoundary fallback={"An error has occured at Admin Onboarding"}>
        <RoutingRulesV3
          step={step}
          togglePageTitle={togglePageTitle}
          tab_type_id={tab_type_id}
          description={getPageToolTip(tab_name, SYSTEM_CONFIGURATION_TABS)}
        />
      </Sentry.ErrorBoundary>
    );
  }
  if (tab_name === "sellfire-forms" && tab_type_id === undefined) {
    return (
      <Sentry.ErrorBoundary fallback={"An error has occured at Admin Onboarding"}>
        <OPSIQFormsPage />
      </Sentry.ErrorBoundary>
    );
  }

  if (tab_name === "sellfire-forms" && tab_type_id !== undefined) {
    return (
      <Sentry.ErrorBoundary fallback={"An error has occured at Admin Onboarding"}>
        <OPSIQFormDetail id={tab_type_id} />
      </Sentry.ErrorBoundary>
    );
  }

  if (tab_name === "sms-settings") {
    return (
      <Sentry.ErrorBoundary fallback={"An error has occured at Admin Onboarding"}>
        <StepSMSSettings />
      </Sentry.ErrorBoundary>
    );
  }

  if (tab_name === "customize-lead-card") {
    return (
      <Sentry.ErrorBoundary fallback={"An error has occured at Admin Onboarding"}>
        <StepCustomizeLeadCard />
      </Sentry.ErrorBoundary>
    );
  }

  return (
    <Sentry.ErrorBoundary fallback={"An error has occured at Admin Onboarding"}>
      <StyledTooltip
        multiline
        place="bottom"
        effect="solid"
        css={{
          fontFamily: theme.PRIMARY_FONT,
        }}
        backgroundColor={theme.PRIMARY800}
        getContent={(dataTip) => (
          <span
            style={{ fontFamily: "Inter", fontStyle: "normal", fontWeight: 600, fontSize: "10px", lineHeight: "14px" }}
          >
            {dataTip}
          </span>
        )}
      />
      <OnboardingColorWrapper>
        {tab_name === "import-leads" && !!dataCurrentImport?.fetchInProgressLeadImport && (
          <HeroImport currentImportData={dataCurrentImport?.fetchInProgressLeadImport} />
        )}
        {tab_name === "import-activity" && !!dataCurrentActivityImport?.fetchInProgressLeadActivityImport && (
          <HeroImport currentImportData={dataCurrentActivityImport?.fetchInProgressLeadActivityImport} />
        )}

        <Main>
          {loggedInUser().role === "ADMIN" ? (
            <OnboardingWrapper>
              {!hidePageTitle && (
                <FlexColumn>
                  {showBackButton && <AppBackButton />}
                  <SimpleFlexDiv>
                    <StepLocationText>{getPageTitle(tab_name, SYSTEM_CONFIGURATION_TABS)}</StepLocationText>
                    <PhoenixIcon
                      style={{ marginLeft: 10 }}
                      data-tip={getPageToolTip(tab_name, SYSTEM_CONFIGURATION_TABS)}
                      svg={info}
                      size={16}
                      variant="neutral"
                    />
                  </SimpleFlexDiv>
                </FlexColumn>
              )}

              <ContentContainer>
                {tab_name === "enablement" && <StepAdmins step={step} blinds={blinds} setBlinds={setBlinds} />}
                {tab_name === "sales-team" && <StepUsersV2 step={step} blinds={blinds} setBlinds={setBlinds} />}
                {tab_name === "field-manager" && !customObject && (
                  <StepCustomFields step={step} blinds={blinds} setBlinds={setBlinds} />
                )}
                {tab_name === "object-manager" && customObject && tab_type_id && (
                  <CustomObjectTable togglePageTitle={togglePageTitle} />
                )}
                {tab_name === "object-manager" && !tab_type_id && <ObjectManager togglePageTitle={togglePageTitle} />}
                {tab_name === "import-leads" && <StepLeadsV2 step={step} blinds={blinds} setBlinds={setBlinds} />}
                {tab_name === "import-activity" && (
                  <ImportLeadActivity step={step} blinds={blinds} setBlinds={setBlinds} />
                )}
                {tab_name === "automated-campaign" && (
                  <StepFollowup step={step} blinds={blinds} setBlinds={setBlinds} />
                )}
                {tab_name === "lead-ranking" && <StepLeadRanking step={step} blinds={blinds} setBlinds={setBlinds} />}

                {tab_name === "revenue-planning" && <StepRevenue step={step} blinds={blinds} setBlinds={setBlinds} />}
                {tab_name === "engagement-rules" && (
                  <StepRestingRules step={step} blinds={blinds} setBlinds={setBlinds} />
                )}
                {tab_name === "integrations" && tab_type_id !== "customize-widget" && tab_type_id === undefined && (
                  <StepIntegrationV2
                    step={step}
                    blinds={blinds}
                    setBlinds={setBlinds}
                    togglePageTitle={togglePageTitle}
                  />
                )}
                {tab_name === "routing-rules" && (
                  <RulesTable
                    step={step}
                    togglePageTitle={togglePageTitle}
                    rule_id={tab_type_id}
                    rule={"lead"}
                    rule_subtitle={"Lead Routing Rules"}
                  />
                )}
                {tab_name === "inbound-concierge" && tab_type_id === "customize-widget" && (
                  <CustomizeWidget togglePageTitle={togglePageTitle} />
                )}
                {tab_name === "inbound-concierge" && tab_type_id !== "customize-widget" && (
                  <BookingWidget rule_id={tab_type_id} />
                )}

                {tab_name === "call-pacing" && <StepCallPacing step={step} blinds={blinds} setBlinds={setBlinds} />}
                {tab_name === "rep-goals" && <StepRepGoals step={step} blinds={blinds} setBlinds={setBlinds} />}

                {tab_name === "recording-storage" && callRecordingStorage && <CallRecordingStorageTab step={step} />}
              </ContentContainer>
            </OnboardingWrapper>
          ) : (
            <OnboardingWrapper style={{ height: "calc(100vh - 150px)" }}>
              <AppErrorText>You do not have access to this page!</AppErrorText>
            </OnboardingWrapper>
          )}
        </Main>
      </OnboardingColorWrapper>
    </Sentry.ErrorBoundary>
  );
};

const StyledTooltip = styled(ReactTooltip)`
  text-transform: none;
  max-width: 400px;
`;

const OnboardingColorWrapper = styled.div`
  background: ${theme.surface.neutral.tertiary};
  position: relative;
  display: flex;
  flex-direction: column;
`;

interface ModalOnProps {
  blinds?: boolean;
}

const OnboardingWrapper = styled.div`
  margin: 0;
`;

const StepLocationText = styled(AppText)`
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const SimpleFlexDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: 39px;
  gap: 6;
`;

const ContentContainer = styled.div`
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: inherit;
  width: 100%;
`;

const Main = styled.div<ModalOnProps>`
  min-height: inherit;
  border: 1px solid ${theme.NEUTRAL100};
  background: ${theme.WHITE_COLOR};
  margin: 24px;
  padding: 24px;
  border-radius: 8px;
  min-width: 1200px;
  box-sizing: border-box;
`;

export { SystemConfiguration };

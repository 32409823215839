import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import moment from "moment";
import React, { useContext, useEffect, useMemo, useState, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { loggedInUser } from "../../apollo/cache";
import { ModalContext } from "../../context";
import {
  ai,
  alert_circle,
  calendar,
  chevron_left,
  clipboard,
  dollar_sign,
  info,
  stop_watch,
} from "../../images/NewDesign";
import { MixpanelActions } from "../../services/mixpanel";
import { CallType } from "../../types";
import { getScoreColor } from "../../utils/misc";
import { theme } from "../../utils/theme";
import { appToast } from "../../utils/toast";
import { FETCH_CONFERENCE_POLL_INTERVAL } from "../../utils/variables";
import { AnalysisTab } from "../Segments/CallReportV2Segments/AnalysisTab";
import { CoachingNotesTab } from "../Segments/CallReportV2Segments/CoachingNotesTab";
import { SoundbitesTab } from "../Segments/CallReportV2Segments/SoundbitesTab";
import { SummaryTab } from "../Segments/CallReportV2Segments/SummaryTab";
import { TranscriptTab } from "../Segments/CallReportV2Segments/TranscriptTab";

import { LeadCardV2 } from "../Segments/DashboardSideBarSegments";
import { AppText, FlexDiv, Loading, SkeletonBlock } from "../UI";
import { PhoenixAppButton, PhoenixIcon, PhoenixProgressBar } from "../UI/Phoenix";
import { CallScoreMoreInfoModal, SaveToFolderModal } from "../modal";
import { CallFeedbackModal } from "../modal/CallFeedbackModal";
import { CoachingNoteModal } from "../modal/CoachingNoteModal";
import { CreateSoundbiteModal } from "../modal/CreateSoundbiteModal";
import { CallReport } from "./CallReports";
import { CallReportSegment } from "../Segments";
import { DispositionReasonPill } from "../UI/Pills";
import { CallHistoryTab } from "../Segments/CallReportV2Segments/CallHistoryTab";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useFirstRender } from "src/utils/hooks";
import { AIProvider, formatConferenceAiData, formatSymbolAiData } from "src/utils/ai";
import WaveSurfer from "wavesurfer.js";
import { useChannel } from "@ably-labs/react-hooks";
import { cloneDeep } from "lodash";

const FETCH_CONFERENCE = gql`
  query fetchConference($conference_id: String!) {
    fetchConference(conference_id: $conference_id) {
      id
      salesperson
      users {
        id
      }
      lead {
        id
        first_name
        last_name
        full_name
        business_name
        city
        state
        channel
        current_lead_type
        industry
        lead_source
        call_notes {
          id
          notes
          created_at
        }
      }
      call_result {
        id
        label
        associated_action
      }
      related_disposition_reason
      recording_url_computed
      duration
      is_call_live
      start_time

      first_user_to_explore_id
      waveform_data_url_computed

      symbol_ai_data
      symbol {
        id
        status
      }
      conference_ai_data
      google_space_info {
        id
        rendered_video_url
      }
    }
  }
`;

const FETCH_CONFERENCE_CALL_STATUS = gql`
  query fetchConferenceCallStatus($conference_id: String!) {
    fetchConference(conference_id: $conference_id) {
      is_call_live
      __typename
    }
  }
`;

const FETCH_CALL_NOTES = gql`
  query fetchCallNotes($conference_id: String!) {
    fetchConference(conference_id: $conference_id) {
      id
      coaching_notes {
        id
        text
        start
        end
      }
      coaching_notes_sorted {
        id
        text
        start
        end
      }
    }
  }
`;

const ADD_COACHING_NOTE = gql`
  mutation addCoachingNote($conference_id: String!, $text: String!, $start: Int!, $end: Int!, $note_id: String) {
    addCoachingNote(conference_id: $conference_id, text: $text, start: $start, end: $end, note_id: $note_id) {
      id
      text
      start
      end
    }
  }
`;

const UPDATE_COACHING_NOTE = gql`
  mutation updateOneNoteItem($noteItemId: String!, $text: String, $start: Int, $end: Int) {
    updateOneNoteItem(note_item_id: $noteItemId, text: $text, start: $start, end: $end) {
      id
      text
      start
      end
    }
  }
`;

const DELETE_COACHING_NOTE = gql`
  mutation DeleteOneNoteItem($noteItemId: String!) {
    deleteOneNoteItem(note_item_id: $noteItemId) {
      id
    }
  }
`;

const FETCH_CALL_REPORTS = gql`
  query fetchCallReportForLead($call_report_lead_args: CallReportLeadArgument!) {
    fetchCallReportForLead(call_report_lead_args: $call_report_lead_args) {
      id
      created_at
      conference {
        id
        duration
        start_time
        recording_url_computed
      }
      related_disposition {
        id
        phase
        person_spoke_to
        type
        label
        associated_action
      }
      schedule_item {
        id
        start_time
        action_label
      }
      user {
        id
        first_name
        last_name
      }
      lead {
        id
        first_name
        last_name
        business_name
        set
        held
        close
      }
    }
  }
`;

const regionColor = "rgba(255,179,56,0.6)";

interface ParamTypes {
  conference_id: string;
  from_system_view?: string;
}

const CallReportV2: React.FC = () => {
  const { conference_id } = useParams<ParamTypes>();
  const { analysisTab } = useFlags();

  // used for mixpanel tracking (OPS-7383)
  const [pageLoaded, setPageLoaded] = useState(false);
  const [peakDataLoading, setPeakDataLoading] = useState(false);

  const [inboundTranscript, setInboundTranscript] = useState<any[]>([]);

  const [selectedTab, setSelectedTab] = useState<
    "summary" | "transcript" | "soundbites" | "analysis" | "coachingNotes" | "callHistory"
  >("summary");

  const [handleTimestampSet, setHandleTimestampSet] = useState<() => void>(() => () => {});

  const [showCallScoreMoreInfo, setShowCallScoreMoreInfo] = useState<{
    title: string;
    score: number;
    description: string;
  } | null>(null);

  const { data, loading, error, refetch } = useQuery(FETCH_CONFERENCE, {
    variables: { conference_id: conference_id },
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
    onCompleted() {
      setPageLoaded(true);
      inboundTranscript.length && setInboundTranscript(data?.fetchConference?.conference_ai_data?.transcript || []);
      console.log("fetchConf data: ", data);
    },
  });

  const { data: conferenceCallStatusData, startPolling, stopPolling } = useQuery(FETCH_CONFERENCE_CALL_STATUS, {
    variables: { conference_id },
    fetchPolicy: "network-only",
    onCompleted(newData) {
      // if call has ended, we need to refetch the full conference data
      const callHasEnded = newData?.fetchConference?.is_call_live === false;
      if (callHasEnded) {
        refetch();
      }
    },
  });

  useEffect(() => {
    const callHasEnded = conferenceCallStatusData?.fetchConference?.is_call_live === false;
    if (callHasEnded) {
      stopPolling();
    } else {
      // ably doesn't care if you call this multiple times it ignores it
      startPolling(FETCH_CONFERENCE_POLL_INTERVAL);
    }
  }, [conferenceCallStatusData?.fetchConference?.is_call_live]);

  useChannel(`conferenceAiStatus:${conference_id}`, (payload) => {
    console.log("conferenceAiStatus: ", payload);

    const transcriptReady = payload.data.status === "TRANSCRIPT_READY";

    const fullAiDataReady = payload.data.status === "COMPLETED";

    if (transcriptReady) {
      console.log("transcriptReady refetching");

      if (payload.data.transcript) {
        setInboundTranscript(payload.data.transcript);
      }

      refetch();
    }

    if (fullAiDataReady) {
      console.log("fullAiDataReady refetching");
      refetch();
    }
  });

  const { data: callNotesData } = useQuery(FETCH_CALL_NOTES, {
    variables: { conference_id: conference_id },
    fetchPolicy: "network-only",
    onCompleted({ fetchConference }) {
      if (!hoistedWaveform) return;
      const regions = fetchConference?.coaching_notes;
      regions &&
        regions.forEach((region: any) => {
          hoistedWaveform?.addRegion({
            ...region,
            color: regionColor,
          });
        });
    },
  });

  const aiProcessing = useMemo(() => {
    const symbolData = data?.fetchConference?.symbol;
    const aiData = data?.fetchConference?.conference_ai_data;

    const isSymbolProcessing = symbolData && (symbolData.status === "IN_PROGRESS" || !symbolData.status);
    const isAiDataProcessing =
      aiData && (aiData.status === "IN_PROGRESS" || aiData?.status === "TRANSCRIPT_READY" || !aiData.status);

    return isSymbolProcessing || isAiDataProcessing;
  }, [data]);

  // need to check for symbl vs assembly
  const wasProcessedByAssembly = data?.fetchConference?.conference_ai_data?.status;
  const transcriptProcessedByAssembly =
    wasProcessedByAssembly && ["TRANSCRIPT_READY", "COMPLETED"].includes(wasProcessedByAssembly);

  const [
    fetchCallReports,
    { data: dataCallReports, loading: loadingCallReports, error: errorCallReports, called: calledCallReports },
  ] = useLazyQuery(FETCH_CALL_REPORTS, {
    fetchPolicy: "network-only",
    onError({ message, name }) {
      console.log(`Error in ${name}: `, message);
    },
  });

  const aiData: any = useMemo(() => {
    if (!data?.fetchConference && !inboundTranscript.length) return null;

    const newData = cloneDeep(data);

    if (!!inboundTranscript.length) {
      newData.fetchConference.conference_ai_data.transcript = inboundTranscript;
    }

    if (data?.fetchConference?.conference_ai_data) {
      return formatConferenceAiData(newData);
    }
    return formatSymbolAiData(newData);
  }, [data, inboundTranscript]);

  const transcriptReady = useMemo(() => {
    const confData = data?.fetchConference?.conference_ai_data;
    const isTranscriptProcessing =
      (confData && confData.status === "TRANSCRIPT_READY") || confData?.status === "COMPLETED";
    return isTranscriptProcessing || !!aiData?.formattedTranscript?.length;
  }, [data, loading, aiData]);

  const callData = useMemo(() => {
    if (!data?.fetchConference) return null;

    return {
      dispositionReason: data?.fetchConference?.call_result?.related_disposition_reason,
      callDisposition: data?.fetchConference?.call_result?.label,
      callType: data?.fetchConference?.call_result?.associated_action,
    };
  }, [data]);

  const {
    setShowCallFeedbackModal,
    showCallFeedbackModal,
    saveCallToLibraryModal,
    setSaveCallToLibraryModal,
    currentConferenceID,
    showSoundbiteModal,
    setShowSoundbiteModal,
    setShowCoachingNotesModal,
    showCoachingNotesModal,
    tempRegionId,
  } = useContext(ModalContext);

  // mixpanel tracking (OPS-7383)

  // ops-7383 - track call report viewed
  useEffect(() => {
    // loaded page state is necessary for is_call_live to be defined
    // conference query is polled so we need to make sure we only track the initial load
    if (!pageLoaded) {
      return;
    }

    MixpanelActions.track("Call Report", {
      type: "call report viewed",
      user_role: loggedInUser()?.role || "N/A",
      is_call_live: data?.fetchConference?.is_call_live,
      duration: data?.fetchConference?.is_call_live ? undefined : data?.fetchConference?.duration,
      first_view: !data?.fetchConference?.first_user_to_explore_id,
    });
  }, [pageLoaded]);

  const [peakData, setPeakData] = useState();

  const [hoistedWaveform, setHoistedWaveform] = useState<WaveSurfer | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      if (!data?.fetchConference?.waveform_data_url_computed) {
        setPeakData(undefined);
        return;
      }

      setPeakDataLoading(true);
      try {
        const response = await fetch(data.fetchConference.waveform_data_url_computed);
        const json = await response.json();
        setPeakData(json.data);
      } catch (error) {
        console.error("Failed to fetch peak data:", error);
        setPeakData(undefined);
      } finally {
        setPeakDataLoading(false);
      }
    };

    fetchData();
  }, [data]);

  const [addCoachingNote, { loading: addCoachingNoteLoading }] = useMutation(ADD_COACHING_NOTE, {
    onCompleted({ addCoachingNote }) {
      console.log("addCoachingNote: ", addCoachingNote);
      if (!addCoachingNote) {
        return;
      }
      MixpanelActions.track("Coaching Note", { type: "add" });

      setShowCoachingNotesModal(false);

      hoistedWaveform && hoistedWaveform.addRegion({ ...addCoachingNote, color: regionColor });

      appToast("Coaching note added!");
    },
    onError({ message }) {
      console.log("Error in addCoachingNote: ", message);
      appToast(message);
      Sentry.captureEvent({
        message: `addCoachingNote GraphQL Error: ${message}`,
      });
    },
    refetchQueries: ["fetchCallNotes"],
  });

  const [editCoachingNote] = useMutation(UPDATE_COACHING_NOTE, {
    onCompleted({ coachingNote }) {
      console.log("editCoachingNote: ", coachingNote);
      if (!coachingNote) {
        return;
      }
    },
    onError({ message }) {
      console.log("Error in editCoachingNote: ", message);
      appToast(message);
      Sentry.captureEvent({
        message: `editCoachingNote GraphQL Error: ${message}`,
      });
    },
  });
  const [deleteCoachingNote] = useMutation(DELETE_COACHING_NOTE, {
    onCompleted({ coachingNote }) {
      console.log("deleteCoachingNote: ", coachingNote);

      if (!coachingNote) {
        return;
      }
    },
    onError({ message }) {
      console.log("Error in deleteCoachingNote: ", message);
      appToast(message);
      Sentry.captureEvent({
        message: `deleteCoachingNote GraphQL Error: ${message}`,
      });
    },
  });

  const handleWaveformRef = (waveform: WaveSurfer | null) => {
    if (!waveform) return;
    setHoistedWaveform(waveform);
  };

  const handleSkipToTimestamp = (timestamp: string) => {
    // find the waveform and skip to the timestamp
    // convert timestamp to minutes
    const timeInMinutes = moment.duration(timestamp).asMinutes();
    // we need to convert to a percentage (0-1)
    const percentage = timeInMinutes / data?.fetchConference?.duration;
    // edge case check. will result in critical error
    const invalidTime = percentage < 0 || percentage > 1;
    if (!hoistedWaveform || invalidTime) return;
    hoistedWaveform?.seekTo(percentage);
  };

  const removeTempRegion = () => {
    if (!hoistedWaveform || !tempRegionId) return;
    const region = hoistedWaveform?.regions?.list[tempRegionId];
    region.remove();
  };

  const history = useHistory();

  const isFirstLoad = useFirstRender();

  const hasHistory = history.length > 1;

  if (isFirstLoad && (loading || peakDataLoading)) return <LoadingComponent />;
  if (error) return <p>Error Loading Call Report</p>;

  if (!data?.fetchConference) return null;

  const {
    latest_call_result,
    is_call_live,
    lead,
    users,
    recording_url_computed,
    salesperson,
    start_time,
    google_space_info,
  } = data.fetchConference;

  const { coaching_notes, coaching_notes_sorted } = callNotesData?.fetchConference || {};

  const formattedCoachingNotes = coaching_notes_sorted?.map(
    (note: { id: string; text: string; start: number; end: number }) => {
      return {
        id: note.id,
        start: note.start,
        end: note.end,
        data: {
          note: note.text,
        },
      };
    },
  );

  const rendered_video_url = google_space_info?.rendered_video_url;

  if (!!is_call_live) {
    // temp
    return <CallReport />;
  }

  return (
    <CallReportContainer>
      {showCallFeedbackModal && (
        <CallFeedbackModal
          blinds={showCallFeedbackModal}
          setBlinds={setShowCallFeedbackModal}
          role="manager"
          conference_id={conference_id}
        />
      )}
      {saveCallToLibraryModal && (
        <SaveToFolderModal
          blinds={saveCallToLibraryModal}
          setBlinds={setSaveCallToLibraryModal}
          conferenceID={currentConferenceID}
        />
      )}
      {!!showCallScoreMoreInfo && (
        <CallScoreMoreInfoModal
          blinds={!!showCallScoreMoreInfo}
          setBlinds={setShowCallScoreMoreInfo}
          showCallScoreMoreInfo={showCallScoreMoreInfo}
        />
      )}
      {showSoundbiteModal && (
        <CreateSoundbiteModal
          blinds={showSoundbiteModal}
          setBlinds={setShowSoundbiteModal}
          duration={data?.fetchConference?.duration}
        />
      )}
      {showCoachingNotesModal && (
        <CoachingNoteModal
          conference_id={conference_id}
          addCoachingNote={addCoachingNote}
          duration={data?.fetchConference?.duration}
          addCoachingNoteLoading={addCoachingNoteLoading}
          removeTempRegion={removeTempRegion}
        />
      )}
      <MainArea>
        <LeftSection>
          <PhoenixAppButton
            variant="brand"
            buttonType="ghost-small"
            onClick={() => {
              if (hasHistory) {
                history.goBack();
              } else {
                history.push("/call-library/");
              }
            }}
          >
            <PhoenixIcon svg={chevron_left} size={16} color={theme.text.brand.primary} />
            <AppText fontSize={10} fontWeight={600} lineHeight={16} uppercase color={theme.text.brand.primary}>
              {hasHistory ? "Back" : "Go to Call Library"}
            </AppText>
          </PhoenixAppButton>
          <SideCard>
            <LeadCardV2
              hideActionButtons
              expandOnRender
              parentType={"leadDetails"}
              leadData={lead}
              width={424}
              expandToHeight
              hideExpandButton
            />
          </SideCard>
        </LeftSection>

        <FlexDiv direction="column" gap={24} style={{ width: "100%", height: "100%" }}>
          <Header>
            <FlexDiv direction="column" gap={8}>
              <HeaderInfoText>Time of Call</HeaderInfoText>
              <FlexDiv gap={8} align="center">
                <PhoenixIcon
                  svg={calendar}
                  size={16}
                  color={theme.icon.brand.default}
                  hoverColor={theme.icon.brand.default}
                />
                <AppText fontSize={12} fontWeight={400} lineHeight={18}>
                  {moment(data?.fetchConference?.start_time).format("MM/DD/YYYY")}
                </AppText>
                <AppText
                  fontSize={12}
                  fontWeight={400}
                  lineHeight={18}
                  color={theme.text.neutral.secondary}
                  style={{
                    whiteSpace: "nowrap",
                  }}
                >
                  {moment(data?.fetchConference?.start_time).format("h:mm A")}
                </AppText>
              </FlexDiv>
            </FlexDiv>
            <FlexDiv direction="column" gap={8}>
              <HeaderInfoText>Duration</HeaderInfoText>
              <FlexDiv gap={8} align="center">
                <PhoenixIcon
                  svg={stop_watch}
                  size={16}
                  color={theme.icon.brand.default}
                  hoverColor={theme.icon.brand.default}
                />
                <AppText fontSize={12} fontWeight={400} lineHeight={18}>
                  {moment
                    .utc(data?.fetchConference?.duration * 1000)
                    .format(data?.fetchConference?.duration >= 3600 ? "HH:mm:ss" : "mm:ss")}
                </AppText>
              </FlexDiv>
            </FlexDiv>
            <FlexDiv direction="column" gap={8} width={140}>
              <HeaderInfoText>Call Type</HeaderInfoText>
              <CallResultPill result={callData?.callType} icon={getCallTypeIcon(callData?.callType)} />
            </FlexDiv>
            <FlexDiv direction="column" gap={8} width={140}>
              <HeaderInfoText>Call Disposition</HeaderInfoText>
              <FlexDiv gap={8} align="center">
                <CallResultPill result={callData?.callDisposition} />
                {callData?.dispositionReason && (
                  <DispositionReasonPill reason={callData?.dispositionReason} disposition={callData?.callDisposition} />
                )}
              </FlexDiv>
            </FlexDiv>
            {/* <FlexDiv direction="column" gap={8} width={160}>
              <FlexDiv gap={8} width="100%" align="center">
                <PhoenixIcon
                  svg={ai}
                  size={16}
                  color={theme.icon.brand.default}
                  hoverColor={theme.icon.brand.default}
                />

                <AppText fontSize={12} fontWeight={500} lineHeight={18} style={{ width: "100%" }}>
                  Sellfire Call Score
                </AppText>
                <AppText fontSize={12} fontWeight={500} lineHeight={18} color={theme.text.success.primary}>
                  {aiData?.callScore?.overall || 0}
                </AppText>
              </FlexDiv>
              <AppText fontSize={12} fontWeight={400} lineHeight={18} color={theme.text.brand.primary}>
                Powered by AI
              </AppText>
            </FlexDiv> */}
          </Header>
          <Main>
            <SectionContainer minWidth="600px">
              <CallReportSegment
                salesperson={salesperson}
                url={recording_url_computed}
                rendered_video_url={rendered_video_url}
                peakData={peakData}
                conference_id={conference_id}
                lead_data={lead}
                coaching_notes={formattedCoachingNotes}
                refetch_call_report={refetch}
                deleteCoachingNote={deleteCoachingNote}
                addCoachingNote={addCoachingNote}
                editCoachingNote={editCoachingNote}
                is_call_live={is_call_live}
                start_time={start_time}
                aiProcessing={aiProcessing}
                showTimeline={data?.fetchConference?.duration >= 20}
                callDuration={data?.fetchConference?.duration}
                aiData={aiData}
                conferenceData={data?.fetchConference}
                setHandleTimestampSet={setHandleTimestampSet}
                onWaveformRef={handleWaveformRef}
              />
              <SectionNav>
                <NavItemFiller width="24px" />
                <NavItem selected={false}>
                  <AppText
                    fontSize={10}
                    fontWeight={600}
                    lineHeight={16}
                    color={theme.text.neutral.secondary}
                    letterSpacing={1}
                    uppercase
                    noWrap
                  >
                    Coaching Notes
                  </AppText>
                </NavItem>
                <NavItemFiller />
              </SectionNav>
              <CoachingNotesTab coachingNotes={coaching_notes || []} handleTimestampSet={handleTimestampSet} />
            </SectionContainer>
            <SectionContainer minWidth="400px">
              <SectionNav>
                <NavItemFiller width="24px" />
                <NavItem selected={selectedTab === "summary"} onClick={() => setSelectedTab("summary")}>
                  <AppText
                    fontSize={10}
                    fontWeight={600}
                    lineHeight={16}
                    color={selectedTab === "summary" ? theme.text.neutral.primary : theme.text.neutral.secondary}
                    letterSpacing={1}
                    uppercase
                    noWrap
                  >
                    Summary
                  </AppText>
                </NavItem>
                <NavItem selected={selectedTab === "transcript"} onClick={() => setSelectedTab("transcript")}>
                  <AppText
                    fontSize={10}
                    fontWeight={600}
                    lineHeight={16}
                    color={selectedTab === "transcript" ? theme.text.neutral.primary : theme.text.neutral.secondary}
                    letterSpacing={1}
                    uppercase
                    noWrap
                  >
                    Transcript
                  </AppText>
                </NavItem>
                {/* <NavItem selected={selectedTab === "soundbites"} onClick={() => setSelectedTab("soundbites")}>
                <AppText
                  fontSize={10}
                  fontWeight={600}
                  lineHeight={16}
                  color={selectedTab === "soundbites" ? theme.text.neutral.primary : theme.text.neutral.secondary}
                  letterSpacing={1}
                  uppercase
                  noWrap
                >
                  Soundbites
                </AppText>
              </NavItem> */}
                {(!!analysisTab || analysisTab === undefined) && aiData?.provider === AIProvider.SYMBOL && (
                  <NavItem selected={selectedTab === "analysis"} onClick={() => setSelectedTab("analysis")}>
                    <AppText
                      fontSize={10}
                      fontWeight={600}
                      lineHeight={16}
                      color={selectedTab === "analysis" ? theme.text.neutral.primary : theme.text.neutral.secondary}
                      letterSpacing={1}
                      uppercase
                      noWrap
                    >
                      Analysis
                    </AppText>
                  </NavItem>
                )}
                <NavItem selected={selectedTab === "callHistory"} onClick={() => setSelectedTab("callHistory")}>
                  <AppText
                    fontSize={10}
                    fontWeight={600}
                    lineHeight={16}
                    color={selectedTab === "callHistory" ? theme.text.neutral.primary : theme.text.neutral.secondary}
                    letterSpacing={1}
                    uppercase
                    noWrap
                  >
                    Call History
                  </AppText>
                </NavItem>
                <NavItemFiller />
              </SectionNav>

              {selectedTab === "summary" && (
                <SummaryTab
                  aiProcessing={aiProcessing}
                  aiData={aiData}
                  refetch_call_report={refetch}
                  deleteCoachingNote={deleteCoachingNote}
                  addCoachingNote={addCoachingNote}
                  editCoachingNote={editCoachingNote}
                  is_call_live={is_call_live}
                  start_time={start_time}
                  salesperson={salesperson}
                  recording_url={recording_url_computed}
                  peakData={peakData}
                  conference_id={conference_id}
                  lead={lead}
                  formattedCoachingNotes={formattedCoachingNotes}
                  conferenceData={data?.fetchConference}
                />
              )}

              {selectedTab === "transcript" && (
                <TranscriptTab
                  // if status is READY BE has finished processing transcript but is still processing rest of AI
                  aiProcessing={aiProcessing || !transcriptProcessedByAssembly}
                  transcriptReady={transcriptReady}
                  aiData={aiData}
                  conferenceData={data?.fetchConference}
                  handleSkipToTimestamp={handleSkipToTimestamp}
                />
              )}

              {selectedTab === "soundbites" && (
                <SoundbitesTab
                  aiProcessing={aiProcessing}
                  refetch_call_report={refetch}
                  deleteCoachingNote={deleteCoachingNote}
                  addCoachingNote={addCoachingNote}
                  editCoachingNote={editCoachingNote}
                  is_call_live={is_call_live}
                  start_time={start_time}
                  salesperson={salesperson}
                  recording_url={recording_url_computed}
                  peakData={peakData}
                  conference_id={conference_id}
                  lead={lead}
                  formattedCoachingNotes={formattedCoachingNotes}
                  conferenceData={data?.fetchConference}
                />
              )}

              {selectedTab === "analysis" && (
                <AnalysisTab aiProcessing={aiProcessing} aiData={aiData} recording_url={recording_url_computed} />
              )}

              {/*moved below waveform section*/}
              {/* {selectedTab === "coachingNotes" && <CoachingNotesTab coachingNotes={coaching_notes || []} />} */}

              {selectedTab === "callHistory" && (
                <CallHistoryTab
                  lead={lead}
                  req={fetchCallReports}
                  called={calledCallReports}
                  data={dataCallReports?.fetchCallReportForLead}
                />
              )}
            </SectionContainer>
          </Main>
        </FlexDiv>

        {/* <RightSection>
          <PhoenixStyledTooltip
            id="call-score-tooltip"
            place="left"
            width={320}
            padding={16}
            getContent={(dataTip) => (
              <>
                <AppText
                  fontSize={14}
                  lineHeight={20}
                  fontWeight={500}
                  color={theme.text.neutral.inverse}
                  style={{ paddingBottom: "8px" }}
                >
                  {dataTip?.split("|")?.[0]}
                </AppText>
                <AppText
                  fontSize={12}
                  lineHeight={18}
                  fontWeight={400}
                  color={theme.text.neutral.inverse}
                  textAlign="left"
                >
                  {dataTip?.split("|")?.[1]}
                </AppText>
              </>
            )}
          />
          {/* <ScoreContainer>
            <FlexDiv gap={8}>
              <PhoenixIcon svg={ai} size={16} color={theme.icon.brand.default} hoverColor={theme.icon.brand.default} />
              <div>
                <AppText fontSize={14} fontWeight={500} lineHeight={16}>
                  Sellfire Call Score
                </AppText>
                <AppText fontSize={10} fontWeight={400} lineHeight={18} color={theme.text.brand.primary}>
                  Powered by AI
                </AppText>
              </div>
              <AppText
                fontSize={16}
                fontWeight={500}
                lineHeight={18}
                color={aiProcessing ? theme.text.neutral.disabled : theme.text.success.primary}
                style={{ marginLeft: "auto" }}
              >
                {aiProcessing ? 0 : aiData?.callScore?.overall || 0}
              </AppText>
            </FlexDiv>

            {!callScoreProcessing ? (
              <FlexDiv direction="column" gap={8}>
                <CallScoreCard
                  title="Communication & Engagement"
                  score={aiData?.callScore?.communicationAndEngagement?.score || 0}
                  textWidth="120px"
                  description={getCallScoreDescription("Communication & Engagement")}
                  onClick={() =>
                    setShowCallScoreMoreInfo({
                      title: "Communication & Engagement",
                      score: aiData?.callScore?.communicationAndEngagement?.score || 0,
                      description: aiData?.callScore?.communicationAndEngagement?.summary,
                    })
                  }
                />
                <CallScoreCard
                  title="Forward Motion"
                  score={aiData?.callScore?.forwardMotion?.score || 0}
                  textWidth="60px"
                  description={getCallScoreDescription("Forward Motion")}
                  onClick={() =>
                    setShowCallScoreMoreInfo({
                      title: "Forward Motion",
                      score: aiData?.callScore?.forwardMotion?.score || 0,
                      description: aiData?.callScore?.forwardMotion?.summary,
                    })
                  }
                />
                <CallScoreCard
                  title="Question Handling"
                  score={aiData?.callScore?.questionHandling?.score || 0}
                  textWidth="60px"
                  description={getCallScoreDescription("Question Handling")}
                  onClick={() =>
                    setShowCallScoreMoreInfo({
                      title: "Question Handling",
                      score: aiData?.callScore?.questionHandling?.score || 0,
                      description: aiData?.callScore?.questionHandling?.summary,
                    })
                  }
                />
                <CallScoreCard
                  title="Sale Process"
                  score={aiData?.callScore?.salesProcess?.score || 0}
                  textWidth="60px"
                  description={getCallScoreDescription("Sale Process")}
                  onClick={() =>
                    setShowCallScoreMoreInfo({
                      title: "Sale Process",
                      score: aiData?.callScore?.salesProcess?.score || 0,
                      description: aiData?.callScore?.salesProcess?.summary,
                    })
                  }
                />
              </FlexDiv>
            ) : (
              <FlexDiv justify="center" style={{ height: "312px", paddingBottom: "40px" }}>
                <AIProcessingCall width={200} />
              </FlexDiv>
            )}
          </ScoreContainer> */}

        {/* <ScoreContainer>
            <FlexDiv gap={16} direction="column">
              <FlexDiv gap={8} align="center">
                <PhoenixIcon svg={clipboard} size={16} variant="brand" hoverColor={theme.icon.brand.default} />
                <AppText fontSize={14} fontWeight={500} lineHeight={20}>
                  Manager Score
                </AppText>
              </FlexDiv>

              <PhoenixAppButton
                buttonType="tertiary"
                uppercase
                buttonTextFontSize={10}
                borderRadius={4}
                width={120}
                style={{ letterSpacing: "1px" }}
              >
                Complete
              </PhoenixAppButton>
            </FlexDiv>
          </ScoreContainer> */}
        {/* </RightSection> */}
      </MainArea>
    </CallReportContainer>
  );
};

const LoadingComponent = () => {
  return (
    <CallReportContainer>
      <MainArea>
        <LeftSection>
          <SkeletonBlock width="100%" height="32px" borderRadius={4} />
          <SideCard>
            <SkeletonBlock width="424px" height="calc(100vh - 150px)" borderRadius={8} />
          </SideCard>
        </LeftSection>

        <FlexDiv direction="column" gap={24} style={{ width: "100%", height: "100%" }}>
          <Header>
            <SkeletonBlock
              width="120px"
              height="48px"
              borderRadius={4}
              style={{
                marginRight: "12px",
              }}
            />
            <SkeletonBlock
              width="120px"
              height="48px"
              borderRadius={4}
              style={{
                marginRight: "12px",
              }}
            />
            <SkeletonBlock
              width="140px"
              height="48px"
              borderRadius={4}
              style={{
                marginRight: "12px",
              }}
            />
            <SkeletonBlock
              width="140px"
              height="48px"
              borderRadius={4}
              style={{
                marginRight: "12px",
              }}
            />
            <SkeletonBlock width="160px" height="48px" borderRadius={4} />
          </Header>
          <Main>
            <SectionContainer minWidth="600px">
              <SkeletonBlock width="100%" height="calc(100vh - 200px)" borderRadius={8} />
            </SectionContainer>
            <SectionContainer minWidth="400px">
              <FlexDiv gap={8} align="center" style={{ padding: 8 }}>
                <SkeletonBlock width="100px" height="32px" borderRadius={4} />
                <SkeletonBlock width="100px" height="32px" borderRadius={4} />
                <SkeletonBlock width="100px" height="32px" borderRadius={4} />
                <SkeletonBlock width="100px" height="32px" borderRadius={4} />
                <SkeletonBlock width="100px" height="32px" borderRadius={4} />
              </FlexDiv>
              <SkeletonBlock width="100%" height="calc(100vh - 264px)" borderRadius={8} />
            </SectionContainer>
          </Main>
        </FlexDiv>

        <RightSection>
          <SkeletonBlock width="100%" height="400px" borderRadius={8} />
          <SkeletonBlock width="100%" height="200px" borderRadius={8} />
        </RightSection>
      </MainArea>
    </CallReportContainer>
  );
};

const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
`;

const Main = styled.div`
  display: flex;
  gap: 16px;
  height: 100%;
  width: 100%;
`;

const CallReportContainer = styled.div`
  position: relative;

  width: calc(100vw - 72px);
  height: 100vh;
  min-width: 1300px;

  padding: 24px;

  background: ${theme.surface.brand.secondary};
  overflow: auto;
`;

const MainArea = styled.div`
  display: flex;
  gap: 40px;
`;

const SideCard = styled.div`
  display: flex;
  flex-direction: column;

  border: 1px solid ${theme.border.neutral.primary};
  border-radius: 8px;
  background: ${theme.fill.neutral.primary};

  overflow: hidden;
  height: calc(100vh - 166px);
`;

const Filler = styled.div`
  flex: 1 1 auto;
  width: 100%;
  background: ${theme.surface.brand.primary};
`;

const HeaderInfoText = styled(AppText)`
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-around;
  padding: 0px 24px;
`;

interface SectionContainerProps {
  minWidth?: string;
}

const SectionContainer = styled.div<SectionContainerProps>`
  width: 100%;
  min-width: ${(props) => props.minWidth ?? "initial"};
  height: calc(100vh - 200px);
  display: flex;
  flex-direction: column;
  border: 1px solid ${theme.border.neutral.primary};
  border-radius: 8px;
  background: ${theme.fill.neutral.primary};
  overflow: auto;
  position: relative;
`;

const SectionNav = styled.div`
  display: flex;

  width: 100%;
  padding-top: 16px;
`;

const NavItemFiller = styled.div<{ width?: string }>`
  background-color: ${theme.WHITE_COLOR};
  border-bottom: 1px solid ${theme.border.neutral.primary};

  width: ${(props) => props.width ?? "100%"};
  height: 32px;
`;

const NavItem = styled.div<{ selected: boolean; onClick?: () => void }>`
  display: flex;
  align-items: center;

  height: 32px;
  padding: 8px 16px;

  background-color: ${theme.WHITE_COLOR};
  border-bottom: ${(props) => (props.selected ? "2px" : "1px")} solid
    ${(props) => (props.selected ? theme.border.brand.primary : theme.border.neutral.primary)};

  cursor: ${(props) => (props.onClick ? "pointer" : "default")};

  transition: border-bottom 0.1s ease-in-out;

  & > p {
    transition: color 0.1s ease-in-out;
    user-select: none;
  }
`;

const RightSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-top: 74px;
`;

const ScoreContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  width: 240px;

  padding: 16px;
  border: 1px solid ${theme.border.neutral.primary};
  border-radius: 8px;
  background: ${theme.fill.neutral.primary};
`;

interface CallScoreCardProps {
  title: string;
  score: number;
  description: string;
  textWidth?: string;
  onClick?: () => void;
}

const CallScoreCard: React.FC<CallScoreCardProps> = ({ title, score, textWidth, description, onClick }) => {
  return (
    <CallScoreCardContainer onClick={onClick}>
      <FlexDiv justify="space-between">
        <AppText fontSize={12} fontWeight={400} lineHeight={18} style={{ width: textWidth }}>
          {title}
        </AppText>

        <div>
          <PhoenixIcon
            svg={info}
            size={16}
            variant="neutral"
            style={{ float: "right" }}
            data-tip={`${title}|${description}`}
            data-for="call-score-tooltip"
          />
          <AppText fontSize={12} fontWeight={600} lineHeight={18}>
            {score}/100
          </AppText>
        </div>
      </FlexDiv>
      <PhoenixProgressBar step={score} maxStep={100} height={4} color={getScoreColor(score)} />
    </CallScoreCardContainer>
  );
};

const CallScoreCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  width: 208px;

  padding: 12px;
  border: 1px solid ${theme.border.neutral.secondary};
  border-radius: 8px;
  background: ${theme.fill.neutral.primary};

  :hover {
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
  }

  transition: box-shadow 0.1s ease-in-out;
  cursor: pointer;
`;

interface CallResultPillProps {
  result: string;
  icon?: string;
}

const CallResultPill: React.FC<CallResultPillProps> = ({ icon, result }) => {
  const isSale = result?.toLowerCase() === "sale";
  const color = isSale ? theme.text.neutral.inverse : theme.text.brand.primary;
  const backgroundColor = isSale ? theme.fill.brand.primary : theme.fill.brand.secondary;
  return (
    <PillContainer backgroundColor={backgroundColor}>
      {icon && <PhoenixIcon svg={icon} size={12} color={color} hoverColor={color} />}
      <AppText fontSize={8} fontWeight={600} lineHeight={12} uppercase color={color}>
        {result || "N/A"}
      </AppText>
    </PillContainer>
  );
};

const PillContainer = styled.div<{ backgroundColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  width: fit-content;
  min-height: 24px;
  height: fit-content;

  padding: 4px 8px;

  background-color: ${(props) => props.backgroundColor};
  border-radius: 4px;
`;

export { CallReportV2 };

const getCallTypeIcon = (callType: CallType) => {
  switch (callType) {
    case "Transfer Demo":
    case "Schedule Follow Up Demo/Schedule Callback":
    case "Schedule Follow Up Demo":
    case "Schedule Demo":
    case "Schedule Decision Call":
    case "Schedule Callback":
    case "Reschedule Demo/Schedule Callback":
    case "Reschedule Demo":
      return clipboard;
    case "Make Sale":
      return dollar_sign;
    default:
      return alert_circle;
  }
};

import styled from "styled-components";
import { theme } from "../../../../utils/theme";
import { AppText, AppSelect, NewAppSelect, StatCard, AppTextArea, FlexDiv } from "../../../UI";
import React, { Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { AiOutlineStar, AiFillStar } from "react-icons/ai";
import { FiChevronUp, FiChevronDown } from "react-icons/fi";
import { NewAppButton } from "../../../UI/NewAppButton";
import { AppErrorText } from "../../../UI";
import * as Sentry from "@sentry/react";

import { displaySanitizedHTML } from "../../../../utils/misc";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";

import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { NewAppIconButton } from "../../../UI";
import { HiPlus } from "react-icons/hi";
import { Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { FormAreaInputField } from "../../../Field";
import { appToast } from "../../../../utils/toast";
import { Loading } from "../../../UI";
import {
  formatPhoneNumber,
  leadNotesTimeFormat,
  formatUSDRaw,
  formatDate,
  convertDate,
} from "../../../../utils/format";
import { CurrentLeadType, OptionItem } from "../../../../types";
import { loggedInUser } from "../../../../apollo/cache";
import { iconNewEdit } from "../../../../images";
import { EditDeleteCallNote } from "./EditDeleteCallNote";
import { CallContext } from "../../../../context";
import { phone_outgoing, plus } from "../../../../images/NewDesign";
import { PhoenixAppButton, PhoenixIcon } from "../../../UI/Phoenix";

interface LeadCardNotesProps {
  primaryLead: any;
  associatedLeads: CurrentLeadType[] | undefined;
  selectedHistoryContactFilter: string;
  setSelectedHistoryContactFilter: (value: string) => void;
  historyData: any;
  dataLead: any;
  fetchLeadHistoryData: any;
  computedLeadData: any;

  loadingAddLeadNote: boolean;
  errorAddLeadNote: boolean;

  loadingFetchNotes: boolean;
  errorFetchNotes: boolean;

  selectedLeadIdForNote: string;
  setSelectedLeadIdForNote: Dispatch<SetStateAction<string>>;
  unsavedNote: string;
  setUnsavedNote: Dispatch<SetStateAction<string>>;
  saveNote: () => void;
  showNewNoteUI: boolean;
  setShowNewNoteUI: Dispatch<SetStateAction<boolean>>;
  resetUnsavedNoteState: () => void;
}

const LeadCardNotes = ({
  primaryLead,
  associatedLeads,
  selectedHistoryContactFilter,
  setSelectedHistoryContactFilter,
  historyData,

  loadingAddLeadNote,
  errorAddLeadNote,

  loadingFetchNotes,
  errorFetchNotes,

  selectedLeadIdForNote,
  setSelectedLeadIdForNote,

  saveNote,

  unsavedNote,
  setUnsavedNote,

  showNewNoteUI,
  setShowNewNoteUI,
  resetUnsavedNoteState,
}: LeadCardNotesProps) => {
  const { includeNumberDialedFrom } = useContext(CallContext);

  const [editCallNote, setEditCallNote] = useState({
    visible: false,
    note: "",
    lead_activity_id: "",
  });

  const contactOptions = useMemo(
    () => [
      {
        label: `${!!primaryLead?.label ? primaryLead.label : "No Name"} (Primary)`,
        value: primaryLead?.value,
      },
      ...(!!associatedLeads
        ? associatedLeads?.map((item: any) => {
            return {
              label: item?.full_name
                ? item.full_name
                : item?.first_name && item?.last_name
                ? `${item.last_name}, ${item.first_name}`
                : "No Name",
              value: item?.id,
            };
          })
        : []),
    ],
    [primaryLead, associatedLeads],
  );

  return (
    <div
      style={{
        backgroundColor: theme.LIGHT_BLUE,
      }}
    >
      <CallNotesHeaderDiv>
        <FilterDiv style={{ flexDirection: "row", alignItems: "center", gap: "8px", margin: "0px" }}>
          <AppText fontSize={12} fontWeight={500} style={{ whiteSpace: "nowrap" }}>
            Filter by Contact
          </AppText>

          <div style={{ width: "100%" }}>
            <NewAppSelect
              options={
                [
                  { label: "Show All", value: "All" },
                  !!primaryLead?.label ? primaryLead : { label: "No Name", value: primaryLead?.value },
                  ...(!!associatedLeads
                    ? (associatedLeads?.map((item: CurrentLeadType) => {
                        return {
                          label: item?.full_name
                            ? item.full_name
                            : item?.first_name && item?.last_name
                            ? `${item.last_name}, ${item.first_name}`
                            : "No Name",
                          value: item?.id,
                        };
                      }) as OptionItem[])
                    : []),
                ] as OptionItem[]
              }
              value={selectedHistoryContactFilter}
              onChange={(e: any) => {
                e?.value && setSelectedHistoryContactFilter(e?.value);
              }}
              noErrorNeeded
            />
          </div>
        </FilterDiv>
      </CallNotesHeaderDiv>
      <>
        <NotesFormDiv>
          {showNewNoteUI ? (
            <>
              <AppTextArea
                backgroundColor={theme.text.neutral.inverse}
                borderColor={theme.NEUTRAL200}
                placeholder="Write a note"
                fixedSize
                width={374}
                value={unsavedNote}
                onChange={(e: any) => setUnsavedNote(e.target.value)}
              />

              <div style={{ width: "100%", paddingTop: "16px" }}>
                <AppText
                  fontSize={12}
                  fontWeight={500}
                  style={{
                    paddingBottom: "16px",
                  }}
                >
                  Choose Contact to Add Note To
                </AppText>
                <NewAppSelect
                  options={contactOptions}
                  value={
                    contactOptions.find((item) => item.value === selectedLeadIdForNote)?.value ||
                    contactOptions[0].value
                  }
                  onChange={(e: any) => {
                    e?.value && setSelectedLeadIdForNote(e?.value);
                  }}
                  placeholder="Select Contact"
                />
              </div>

              <FilterDiv
                style={{
                  paddingBottom: "20px",
                  justifyContent: "flex-end",
                  flexDirection: "row",
                  gap: "16px",
                }}
              >
                <div style={{ width: "100px" }}>
                  <PhoenixAppButton
                    buttonTextFontSize={10}
                    buttonType="primary"
                    height={40}
                    style={{ backgroundColor: "transparent" }}
                    variant="danger-outline"
                    onClick={() => {
                      resetUnsavedNoteState();
                    }}
                  >
                    CANCEL
                  </PhoenixAppButton>
                </div>
                <div style={{ width: "100px" }}>
                  <PhoenixAppButton
                    buttonTextFontSize={10}
                    buttonType="primary"
                    disabled={loadingAddLeadNote || !selectedLeadIdForNote || !unsavedNote.length}
                    height={40}
                    style={{ backgroundColor: "transparent" }}
                    type="submit"
                    variant="brand-outline"
                    onClick={() => {
                      saveNote && saveNote();
                    }}
                  >
                    SAVE
                  </PhoenixAppButton>
                </div>
              </FilterDiv>
            </>
          ) : (
            <FilterDiv>
              <PhoenixAppButton
                variant="brand"
                buttonType="secondary"
                onClick={() => {
                  setUnsavedNote("");
                  setShowNewNoteUI(!showNewNoteUI);
                }}
                width={172}
              >
                <FlexDiv align="center" gap={16}>
                  <PhoenixIcon svg={plus} size={16} color={theme.WHITE_COLOR} hoverColor={theme.WHITE_COLOR} />
                  <AppText
                    uppercase
                    fontSize={10}
                    fontWeight={600}
                    lineHeight={16}
                    letterSpacing={1}
                    color={theme.text.neutral.inverse}
                  >
                    Add New Note
                  </AppText>
                </FlexDiv>
              </PhoenixAppButton>
            </FilterDiv>
          )}
        </NotesFormDiv>
        {editCallNote && editCallNote?.visible ? (
          <EditDeleteCallNote
            lead_activity_id={editCallNote.lead_activity_id}
            note={editCallNote.note}
            editCallNote={editCallNote}
            setEditCallNote={setEditCallNote}
          />
        ) : loadingFetchNotes ? (
          <Loading />
        ) : errorFetchNotes ? (
          <AppErrorText>Unable To Fetch Notes</AppErrorText>
        ) : !!historyData?.fetchLeadHistory && historyData?.fetchLeadHistory?.length > 0 ? (
          <div
            style={{
              maxHeight: "450px",
              overflowY: "scroll",
              padding: "0px 16px",
            }}
          >
            {historyData?.fetchLeadHistory?.map((item: any) => (
              <CallNotesDiv key={item.id}>
                {!!item.user?.full_name && <CallNotesText bold>{item.user?.full_name}</CallNotesText>}

                {includeNumberDialedFrom && (
                  <FlexDiv align="center" gap={8}>
                    <PhoenixIcon
                      svg={phone_outgoing}
                      size={16}
                      color={theme.icon.brand.default}
                      hoverColor={theme.icon.brand.default}
                    />
                    <CallNotesText>{formatPhoneNumber(item.user?.phone_number)}</CallNotesText>
                  </FlexDiv>
                )}

                {!!item.created_at && <CallNotesText>{leadNotesTimeFormat(item.created_at)}</CallNotesText>}

                {!!item?.html ? (
                  <CallNotesText> {displaySanitizedHTML(item.notes)}</CallNotesText>
                ) : (
                  <CallNotesText>{item.notes}</CallNotesText>
                )}
                {item?.user?.id === loggedInUser()?.id && (
                  <EditPencil
                    src={iconNewEdit}
                    alt="edit"
                    onClick={() => setEditCallNote({ visible: true, note: item.notes, lead_activity_id: item.id })}
                  />
                )}
              </CallNotesDiv>
            ))}
          </div>
        ) : (
          <EmptyDiv>
            <EmptyText>No notes yet</EmptyText>
          </EmptyDiv>
        )}
      </>
    </div>
  );
};

export { LeadCardNotes };

const EditPencil = styled.img`
  width: 16px;
  height: 16px;
  cursor: pointer;
`;

const PersonNumberDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${theme.NEUTRAL200};
  padding-top: 10px;
  padding-bottom: 10px;
`;

const CallNotesHeaderDiv = styled(PersonNumberDiv)`
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  height: auto;
  width: 100%;
  border-bottom: none;
`;

const FilterDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
`;

const NotesFormDiv = styled(PersonNumberDiv)`
  align-items: flex-start;
  border-bottom: none;
  flex-direction: column;
  gap: 4px;
  height: auto;
  padding: 16px 24px 0;
`;

const CallNotesDiv = styled(PersonNumberDiv)`
  align-items: flex-start;
  border-bottom: none;
  border-top: 1px solid ${theme.NEUTRAL200};
  flex-direction: column;
  gap: 4px;
  height: auto;
`;

interface CustomFieldTextProps {
  bold?: boolean;
}

const CallNotesText = styled(AppText)<CustomFieldTextProps>`
  font-weight: ${(props) => (props.bold ? 600 : "normal")};
  margin: 0px;

  max-width: 380px;
  white-space: pre-wrap;
  word-wrap: break-word;
`;

const EmptyDiv = styled.div`
  height: 84px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 1px solid ${theme.NEUTRAL200};
  background-color: ${theme.LIGHT_BLUE};
`;

const EmptyText = styled(AppText)`
  font-weight: 400;
  font-size: 12px;
`;

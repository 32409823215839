/* eslint-disable @typescript-eslint/no-non-null-assertion */
import styled, { keyframes } from "styled-components";
import * as Sentry from "@sentry/react";
import * as React from "react";
import { useState, useRef, useCallback, useEffect, useContext } from "react";
import { AppButton, AppText, Loading, NewAppButton, FlexDiv } from "../../UI";
import { theme } from "../../../utils/theme";
import { useMutation, useQuery, gql } from "@apollo/client";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { UserCSVUpload, UpdateUserModalV2, ManageSalesTeamsColumnsModal, SalesTeamsBulkEditModal } from "../../modal";
import { toast } from "react-toastify";
import { USER_CSV_EXAMPLE_URL } from "../../../utils/variables";
import { restAPI } from "../../../apollo";
import { truncate } from "fs/promises";
import { appToast } from "../../../utils/toast";
import { EditableInputField } from "../../Field/EditableInputField";
import { carrotDropdown, searchIcon, iconNewEdit, iconRefresh, iconGrid, iconList } from "../../../images";
import { SalesTeamsTiles } from "./SalesTeamsTiles";
import { SalesTeamsList } from "./SalesTeamsList";
import { ModalContext } from "../../../context";
import { trash } from "../../../images/NewDesign";
import { PhoenixIcon } from "../../UI/Phoenix";

interface DisappearingDivProps {
  step: number;
  blinds: boolean;
  setBlinds: (blinds: boolean) => void;
  isOnboarding?: boolean;
}

const defaultModalUser = {
  id: "",
  role: "",
  email: "",
  first_name: "",
  last_name: "",
  phone_number: "",
  team_name: "",
  site: {
    id: "",
    name: "",
  },
  manager_id: "",
  team_id: "",
  close_rate: 0,
  // taking_demos: true,
  default_availability: true,
  holds_per_prospect_vs_held_demo_per_dial: 0,
  user_custom_availability: undefined,
  visible: false,
  timezone: "",
  industry_filter: false,
  industry_white_list_array: [],
  sub_industry_white_list_array: [],
  lead_source_filter: false,
  lead_source_white_list_array: [],
  custom_fields_white_list_array: [],
  regions_white_list_array: [],
  regions_white_list_assign_type: "OnlyAssign",
  industry_white_list_assign_type: "OnlyAssign",
  sub_industry_white_list_assign_type: "OnlyAssign",
  lead_source_white_list_assign_type: "OnlyAssign",
  channel: "Both",
  auto_assign_no_phone_leads: false,
  assigned_countries: [],
  lead_history_filter: undefined,
};

const defaultCSVModal = {
  num_imported: undefined,
  num_updated: undefined,
  num_error: undefined,
  error_report_url: undefined,
  upload_id: undefined,
  num_stagged: undefined,
  visible: false,
};

const FETCH_UNASSIGNED_USERS = gql`
  query fetchUnassignedUsers {
    fetchUnassignedUsers {
      id
      email
      first_name
      last_name
      full_name
      last_name_first_name
      role
      phone_number
      team_id
      team_name
      manager_id
      close_rate
      metric
      taking_demos
      default_availability
      holds_per_prospect_vs_held_demo_per_dial
      timezone
      industry_filter
      industry_white_list_array
      sub_industry_white_list_array
      lead_source_filter
      lead_source_white_list_array
      regions_white_list_array
      regions_white_list_assign_type
      industry_white_list_assign_type
      sub_industry_white_list_assign_type
      lead_source_white_list_assign_type
      auto_assign_no_phone_leads
      assigned_countries
      lead_history_filter
      user_custom_availability {
        id
        day
        start_hour
        start_minute
        end_hour
        end_minute
        start_time
        end_time
      }
      channel
      regions_white_list_array
      current_team_start_date
    }
  }
`;

const ASSIGN_USER_TO_TEAM = gql`
  mutation assignUserToTeam($user_id: String!, $team_id: String) {
    assignUserToTeam(user_id: $user_id, team_id: $team_id) {
      id
      role
      email
      first_name
      last_name
      full_name
      last_name_first_name
      phone_number
      team_id
      team_name
      manager_id
      close_rate
      metric
      taking_demos
      default_availability
      holds_per_prospect_vs_held_demo_per_dial
      timezone
      industry_filter
      industry_white_list_array
      regions_white_list_array
      sub_industry_white_list_array
      lead_source_filter
      lead_source_white_list_array
      regions_white_list_assign_type
      industry_white_list_assign_type
      sub_industry_white_list_assign_type
      lead_source_white_list_assign_type
      auto_assign_no_phone_leads
      assigned_countries
      lead_history_filter
      user_custom_availability {
        id
        day
        start_minute
        start_hour
        end_minute
        end_hour
      }
      channel
      team {
        id
        name
        name
        average_close_rate
        average_holds_per_prospect_vs_held_demo_per_dial
        label
      }
    }
  }
`;

const UPDATE_ORG = gql`
  mutation updateRepNoteView($reps_can_view_all_notes: Boolean!) {
    updateRepNoteView(reps_can_view_all_notes: $reps_can_view_all_notes) {
      id
      reps_can_view_all_notes
    }
  }
`;

const FETCH_ORG = gql`
  query fetchOrganization {
    fetchOrganization {
      id
      reps_can_view_all_notes
    }
  }
`;

const FETCH_TEAMS = gql`
  query fetchTeams {
    fetchTeams {
      id
      created_at
      name
      average_close_rate
      average_holds_per_prospect_vs_held_demo_per_dial
      label
      site {
        id
        name
      }
      team_leader {
        id
        email
        first_name
        last_name
        full_name
        last_name_first_name
        role
        team_id
        team {
          site_id
        }
        phone_number
        close_rate
        metric
        holds_per_prospect_vs_held_demo_per_dial
        taking_demos
        team_name
        default_availability
        timezone
        user_custom_availability {
          id
          day
          start_hour
          start_minute
          end_hour
          end_minute
          start_time
          end_time
        }
        channel
      }
      active_members {
        id
        email
        first_name
        last_name
        full_name
        last_name_first_name
        role
        team_id
        team {
          site_id
        }
        phone_number
        close_rate
        metric
        holds_per_prospect_vs_held_demo_per_dial
        taking_demos
        team_name
        default_availability
        timezone
        industry_filter
        industry_white_list_array
        sub_industry_white_list_array
        lead_source_filter
        regions_white_list_array
        lead_source_white_list_array
        regions_white_list_assign_type
        industry_white_list_assign_type
        sub_industry_white_list_assign_type
        lead_source_white_list_assign_type
        auto_assign_no_phone_leads
        assigned_countries
        lead_history_filter
        custom_field_white_list_array {
          custom_field {
            id
          }
          assign
          selected
        }
        lead_source_white_list_array
        leads_in_queue
        user_custom_availability {
          id
          day
          start_hour
          start_minute
          end_hour
          end_minute
          start_time
          end_time
        }
        channel
        regions_white_list_array
        current_team_start_date
        manager_id
      }
    }
  }
`;

const REFILL_LEAD_QUEUE = gql`
  mutation refillLeadQueue($user_id: String!) {
    refillLeadQueue(user_id: $user_id)
  }
`;

const FETCH_SALES_LIST_COLUMNS = gql`
  query fetchSalesTeamColumnOptions {
    fetchSalesTeamColumnOptions {
      label
      value
    }
  }
`;

const StepUsersV2: React.FC<DisappearingDivProps> = ({ step, isOnboarding }) => {
  const [userModal, setUserModal] = useState(defaultModalUser);
  const [csvModal, setCSVModal] = useState(defaultCSVModal);
  const [csvLoading, setCsvLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [sortBy, setSortBy] = useState(false);
  const [listView, setListView] = useState(false);
  const [listSelected, setListSelected] = useState({});
  const [columns, setColumns] = useState([]);
  const [tempSelected, setTempSelected] = useState([]);
  const gridRef: any = useRef(); // accessing Grid's API

  const {
    showSalesTeamsManageColumnsModal,
    setShowSalesTeamsManageColumnsModal,
    showSalesTeamsBulkEditModal,
    setShowSalesTeamsBulkEditModal,
  } = useContext(ModalContext);

  const getCsvParams = useCallback(() => {
    return {
      allColumns: true,
      columnKeys: [
        "Id",
        "SalesPerson",
        "Email",
        "Role",
        "PhoneNumber",
        "Team",
        "Timezone",
        "PrimaryIndustry",
        "SubIndustry",
        "LeadSource",
        "State",
        "Channel",
      ],
    };
  }, []);

  const [updateOrg, { loading: updateOrgLoading, error: updateOrgError }] = useMutation(UPDATE_ORG, {
    onCompleted({ updateRepNoteView }) {
      if (!updateRepNoteView) {
        appToast("Error: Something went wrong!");
        return;
      }
      appToast("Setting updated");
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `updateOrg GraphQL Error: ${message}`,
      });
      console.log("Error in updateOrg: ", message);
    },
  });

  const [assignUserToTeam, { loading: assignLoading, error: assignError }] = useMutation(ASSIGN_USER_TO_TEAM, {
    onCompleted({ assignUserToTeam }) {
      console.log("Add admin: ", assignUserToTeam);
      if (!assignUserToTeam) {
        appToast("Error: Something went wrong!");
        return;
      }
      appToast("User team assignment changed");
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `assignUserToTeam GraphQL Error: ${message}`,
      });

      console.log("Error in addUser: ", message);
    },
    update(cache, { data, data: { assignUserToTeam } }) {
      console.log("data in update", data);
      if (!assignUserToTeam.team_id) {
        cache.modify({
          fields: {
            fetchTeams(existingTeams, { readField }) {
              existingTeams.forEach((team_ref: any) => {
                cache.modify({
                  id: cache.identify(team_ref),
                  fields: {
                    active_members(existingMembers, { readField }) {
                      return existingMembers.filter((m: any) => assignUserToTeam.id !== readField("id", m));
                    },
                  },
                });
              });
              return existingTeams;
            },
            fetchUnassignedUsers(existingUsers, { readField }) {
              const newUserRef = cache.writeFragment({
                data: assignUserToTeam,
                fragment: gql`
                  fragment NewUser on User {
                    id
                    role
                    email
                    first_name
                    last_name
                    full_name
                    last_name_first_name
                    phone_number
                    team_name
                    Site {
                      id
                      name
                    }
                    manager_id
                    close_rate
                    metric
                    taking_demos
                    default_availability
                    holds_per_prospect_vs_held_demo_per_dial
                    timezone
                    industry_filter
                    industry_white_list_array
                    sub_industry_white_list_array
                    lead_source_filter
                    lead_source_white_list_array
                    regions_white_list_assign_type
                    industry_white_list_assign_type
                    sub_industry_white_list_assign_type
                    lead_source_white_list_assign_type
                    lead_history_filter
                    channel
                    team {
                      id
                      name
                      name
                      average_close_rate
                      average_holds_per_prospect_vs_held_demo_per_dial
                      label
                    }
                    user_custom_availability {
                      id
                      day
                      start_minute
                      start_hour
                      end_minute
                      end_hour
                    }
                    c
                  }
                `,
              });
              return [...existingUsers, newUserRef];
            },
          },
        });
      } else {
        // Assign user to team
        cache.modify({
          fields: {
            fetchUnassignedUsers(existingUsers, { readField }) {
              return existingUsers.filter((ref: any) => assignUserToTeam.id !== readField("id", ref));
            },
          },
        });
        cache.modify({
          id: cache.identify(assignUserToTeam.team),
          fields: {
            active_members(existingMembers, { readField }) {
              return [...existingMembers, assignUserToTeam];
            },
          },
        });
      }
    },
  });

  const { data: columnsData, loading: columnsLoading, error: columnsError, refetch: columnsRefetch } = useQuery(
    FETCH_SALES_LIST_COLUMNS,
    {
      fetchPolicy: "network-only",
      onError({ message, name }) {
        // Sentry.captureEvent({
        //   message: `${name} GraphQL Error: ${message}`,
        // });
        console.log(`Error in ${name}: `, message);
      },
    },
  );

  const [refillLeadQueue, { loading: loadingRefill, error: errorRefill }] = useMutation(REFILL_LEAD_QUEUE, {
    onCompleted({ refillLeadQueue }) {
      if (!refillLeadQueue) {
        appToast("Error refilling queue.");
        return;
      }
      appToast("User's queue has been refilled!");
    },
    onError({ message }) {
      appToast(message);
      Sentry.captureEvent({
        message: `refillLeadQueue GraphQL Error: ${message}`,
      });
      console.log("Error in refillLeadQueue: ", message);
    },
  });

  const {
    data: usersData,
    loading: unassignedUsersLoading,
    error: unassignedUsersError,
    refetch: userRefetch,
  } = useQuery(FETCH_UNASSIGNED_USERS, {
    fetchPolicy: "network-only",
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const { data: teamsData, loading: teamsLoading, error: teamsError, refetch: teamRefetch } = useQuery(FETCH_TEAMS, {
    fetchPolicy: "cache-and-network",
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const { data: orgData, loading: orgLoading, error: orgError, refetch: orgRefetch } = useQuery(FETCH_ORG, {
    fetchPolicy: "network-only",
    onError({ message, name }) {
      // Sentry.captureEvent({
      //   message: `${name} GraphQL Error: ${message}`,
      // });
      console.log(`Error in ${name}: `, message);
    },
  });

  const [repSelect, setRepSelect] = useState(orgLoading ? "" : `${orgData.fetchOrganization.reps_can_view_all_notes}`);

  const unassigned_users = usersData?.fetchUnassignedUsers.filter((user: any) => {
    const name = user.full_name.toLowerCase();
    return name.includes(searchText.toLowerCase());
  });
  const assigned_users = teamsData?.fetchTeams
    .reduce((acc: any, cv: any) => [...acc, ...cv.active_members], [])
    .filter((user: any) => {
      const name = user.full_name.toLowerCase();
      return name.includes(searchText.toLowerCase());
    });

  const [allUsers, setAllUsers] = useState<any>([]);
  const [teams, setTeams] = useState<any[]>([]);
  const [unassignedUsers, setUnassignedUsers] = useState<any[]>([]);

  useEffect(() => {
    if (unassigned_users && assigned_users && !showSalesTeamsBulkEditModal) {
      setAllUsers([...unassigned_users, ...assigned_users].filter((user: any) => user.role !== "ADMIN"));
    }
  }, [usersData, teamsData, showSalesTeamsBulkEditModal, searchText]);
  useEffect(() => {
    setColumns(columnsData?.fetchSalesTeamColumnOptions);
    setTempSelected(columnsData?.fetchSalesTeamColumnOptions);
  }, [columnsData, columnsLoading, columnsError]);

  if (unassignedUsersLoading || !usersData) return <Loading />;
  if (unassignedUsersError) {
    return <p>Error fetching users</p>;
  }

  if (teamsLoading || !teamsData) return <Loading />;
  if (teamsError) {
    return <p>Error fetching teams</p>;
  }

  if (orgLoading || !orgData) return <Loading />;
  if (orgError) {
    return <p>Error org settings</p>;
  }

  const onDragEnd = async (result: any) => {
    console.log("result: ", result);
    const { source, destination, draggableId: user_id } = result;
    console.log("source: ", source);
    console.log("destination: ", destination);
    console.log("destination: ", destination);
    // dropped outside the list
    if (!destination) {
      return;
    }
    const sInd = source.droppableId;
    const dInd = destination.droppableId;
    // If source and destination droppable ids are same, don't change anything
    if (sInd === dInd) {
      return;
    }
    const team_id = dInd === "unassigned" ? undefined : dInd;
    // Can get optimistic response here as well
    console.log("allUsers: ", allUsers);
    const user_list: any = allUsers.filter((u: any) => u.id === user_id);
    console.log("user_list: ", user_list);
    const optimistic_user = user_list.length ? { ...user_list[0], team_id } : { id: user_id, team_id };

    assignUserToTeam({
      variables: {
        user_id,
        team_id,
      },
      optimisticResponse: { assignUserToTeam: optimistic_user },
    });
    userRefetch();
    teamRefetch();
    // if destination is unassigned, called unassignUser mutation
    // If destination is truthy id, call the assignTeam mutation
  };

  const onFileChange = async (event: any) => {
    setCsvLoading(true);
    setCSVModal(defaultCSVModal);
    // Create an object of formData
    const formData = new FormData();
    // Update the formData object
    formData.append("file", event.target.files[0]);
    // Request made to the backend api
    // Send formData object
    try {
      const response = await restAPI.post(`/upload/userCSV`, formData);
      console.log("response: ", response);
      console.log("resusers: ", response.data);
      setCSVModal({ ...response.data, visible: true });
      teamRefetch();
      userRefetch();
      setCsvLoading(false);
    } catch (e: any) {
      console.log("error uploading csv: ", e);
      appToast(`${e?.response?.data?.error}` ?? "Something went wrong!");
      setCsvLoading(false);
    }
  };

  const checkDisabled = () =>
    Object.values(listSelected).filter((ele: any) => ele.role === "SM" || ele.role === "ADMIN").length;

  return (
    <>
      {showSalesTeamsManageColumnsModal && (
        <DarkDiv>
          <SlideInDiv>
            <ManageSalesTeamsColumnsModal
              columns={columns}
              tempSelected={tempSelected}
              setTempSelected={setTempSelected}
            />
          </SlideInDiv>
        </DarkDiv>
      )}
      {showSalesTeamsBulkEditModal && (
        <SalesTeamsBulkEditModal
          selectedUsers={listSelected}
          setListSelected={setListSelected}
          allUsers={allUsers}
          setAllUsers={setAllUsers}
        />
      )}
      {userModal.visible && (
        <UpdateUserModalV2
          close={() => setUserModal(defaultModalUser)}
          tileState={{ teams, setTeams, unassignedUsers, setUnassignedUsers }}
          {...userModal}
        />
      )}
      {csvModal.visible && <UserCSVUpload close={() => setCSVModal(defaultCSVModal)} {...csvModal} />}
      <DisappearingDiv>
        <DragDropContext onDragEnd={onDragEnd}>
          <TopAlignDiv>
            <OptionsDiv>
              {csvLoading ? (
                <Loading />
              ) : (
                <>
                  <OptionsLeft>
                    <ActionButton
                      variant="primary"
                      width={171}
                      onClick={() => setUserModal({ ...defaultModalUser, visible: true })}
                    >
                      Add New Sales Rep
                    </ActionButton>
                    <FileInputLabel>
                      <input style={{ display: "none" }} type="file" onChange={onFileChange} />
                      <p>Import CSV</p>
                    </FileInputLabel>
                    <a href={USER_CSV_EXAMPLE_URL} style={{ textDecoration: "none" }}>
                      <ActionButton variant="secondary">Download CSV Template</ActionButton>
                    </a>
                  </OptionsLeft>
                  <OptionsRight>
                    {!listView && (
                      <SelectContainer>
                        <RepsCanViewSelect
                          value={repSelect}
                          onChange={async (e) => {
                            e.preventDefault();
                            setRepSelect(e.target.value);
                            if (!e.target.value || e.target.value === "placeholder") {
                              return;
                            }
                            await updateOrg({
                              variables: {
                                reps_can_view_all_notes: e.target.value === "true",
                              },
                            });
                          }}
                        >
                          {orgLoading && <option value={""}>Loading...</option>}
                          <option value={"placeholder"} hidden>
                            Reps Can View:
                          </option>
                          <option value={"true"}>All Prior Call Notes</option>
                          {/* eslint-disable-next-line react/no-unescaped-entities */}
                          <option value={"false"}>Only Rep's Call Notes</option>
                        </RepsCanViewSelect>
                      </SelectContainer>
                    )}
                    {listView && (
                      <ActionButton
                        variant="secondary"
                        width={"fit-content"}
                        onClick={() => gridRef.current.api.exportDataAsCsv(getCsvParams())}
                      >
                        Export as CSV
                      </ActionButton>
                    )}
                    <SearchBoxContainer>
                      <SearchInput
                        type="text"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        placeholder="Search Users"
                      />
                      <Icon url={searchIcon} color={theme.PRIMARY500} size="14" top="14" left="10" />
                    </SearchBoxContainer>
                  </OptionsRight>
                </>
              )}
            </OptionsDiv>
            <TeamMembersLegend>
              <LegendLeft>
                {!listView ? (
                  <>
                    <AppText fontSize={12}>Sort Teams By:</AppText>
                    <FlexDiv align="center" gap={8}>
                      <FlexDiv align="center">
                        <input
                          type="radio"
                          name="sortBy"
                          checked={!sortBy}
                          onChange={() => setSortBy(false)}
                          style={{ width: "16px", height: "16px" }}
                        ></input>
                        <SwitchLabel sortBy={sortBy} alphabetical={true}>
                          A-Z
                        </SwitchLabel>
                      </FlexDiv>
                      <FlexDiv align="center">
                        <input
                          type="radio"
                          name="sortBy"
                          checked={sortBy}
                          onChange={() => setSortBy(true)}
                          style={{ width: "16px", height: "16px" }}
                        ></input>
                        <SwitchLabel sortBy={sortBy} alphabetical={false}>
                          Metric
                        </SwitchLabel>
                      </FlexDiv>
                    </FlexDiv>
                    <FlexDiv align="center" gap={8}>
                      <ColorCard variant="primary">AE</ColorCard>
                      <AppText fontSize={10}>Close Rate in Last 90 Days</AppText>
                    </FlexDiv>
                    <FlexDiv align="center" gap={8}>
                      <ColorCard variant="secondary">SDR</ColorCard>
                      <AppText fontSize={10}>Holds Per 100 Prospects in Last 90 Days</AppText>
                    </FlexDiv>
                  </>
                ) : (
                  <FlexDiv gap={16} align="center">
                    <SelectContainer>
                      <RepsCanViewSelect
                        value={"placeholder"}
                        onChange={(e) => {
                          setShowSalesTeamsBulkEditModal(e.target.value);
                        }}
                      >
                        {orgLoading && <option value={""}>Loading...</option>}
                        <option value={"placeholder"} hidden>
                          Edit Selected Users
                        </option>
                        {!checkDisabled() && (
                          <>
                            <option value={"Role Type"}>Role Type</option>
                            <option value={"Team"}>Team</option>
                          </>
                        )}
                        <option value={"Industry"}>Industry</option>
                        <option value={"Lead Sources"}>Lead Sources</option>
                        <option value={"Time Zone"}>Time Zone</option>
                        <option value={"Channel"}>Channel</option>
                      </RepsCanViewSelect>
                    </SelectContainer>
                    <PhoenixIcon
                      svg={trash}
                      color={!!Object.values(listSelected).length ? theme.PRIMARY500 : theme.PILL_GRAY}
                      size={22}
                      style={{
                        position: "relative",
                        width: "24px",
                        height: "24px",
                        cursor: !!Object.values(listSelected).length ? "pointer" : "unset",
                      }}
                      onClick={() => !!Object.values(listSelected).length && setShowSalesTeamsBulkEditModal("Delete")}
                    />
                  </FlexDiv>
                )}
              </LegendLeft>
              {!isOnboarding && (
                <LegendRight>
                  <ViewWrap onClick={() => setListView(false)} style={{ borderRight: `1px solid ${theme.PILL_GRAY}` }}>
                    <Icon
                      url={iconGrid}
                      color={!listView ? theme.PRIMARY500 : theme.PILL_GRAY}
                      size="16"
                      top="2"
                      left="-13"
                    />
                    <ViewText style={{ paddingRight: "16px", color: !listView ? theme.PRIMARY500 : theme.PILL_GRAY }}>
                      Chart View
                    </ViewText>
                  </ViewWrap>
                  <ViewWrap onClick={() => setListView(true)}>
                    <Icon
                      url={iconList}
                      color={listView ? theme.PRIMARY500 : theme.PILL_GRAY}
                      size="16"
                      top="2"
                      left="-13"
                    />
                    <ViewText style={{ color: listView ? theme.PRIMARY500 : theme.PILL_GRAY }}>List View</ViewText>
                  </ViewWrap>
                </LegendRight>
              )}
            </TeamMembersLegend>

            <ManageColumns>
              {listView && (
                <AppText
                  fontWeight={600}
                  variant="primary"
                  style={{ cursor: "pointer", borderBottom: "none" }}
                  onClick={() => setShowSalesTeamsManageColumnsModal(!showSalesTeamsManageColumnsModal)}
                >
                  Manage Columns
                </AppText>
              )}
            </ManageColumns>

            {listView ? (
              <SalesTeamsList
                unassignedUsersLoading={unassignedUsersLoading}
                teamsLoading={teamsLoading}
                usersData={usersData}
                teamsData={teamsData}
                searchText={searchText}
                listSelected={listSelected}
                setListSelected={setListSelected}
                gridRef={gridRef}
                allUsers={allUsers}
                columns={columns}
              />
            ) : (
              <SalesTeamsTiles
                step={step}
                isOnboarding={isOnboarding}
                sortBy={sortBy}
                searchText={searchText}
                tileState={{ teams, unassignedUsers, setTeams, setUnassignedUsers }}
              />
            )}
          </TopAlignDiv>
        </DragDropContext>
      </DisappearingDiv>
    </>
  );
};

const RepsCanViewSelect = styled.select`
  position: relative;
  width: 224px;
  height: 40px;

  border-radius: 4.4px;
  border: solid 0.9px ${theme.PILL_GRAY};
  padding: 8px;

  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
`;

const FileInputLabel = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: 40px;
  padding: 0px 24px;

  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;

  border-radius: 8px;
  border: solid 1px ${theme.PRIMARY500};
  color: ${theme.PRIMARY500};
  background-color: ${theme.WHITE_COLOR};
  cursor: pointer;

  transition: all 0.15s ease-in-out;

  :hover {
    background-color: ${theme.PRIMARY200};
    border-color: ${theme.PRIMARY300};
  }
`;

const SelectContainer = styled.div`
  position: relative;
  background: url(${carrotDropdown}) no-repeat;
  background-position: right 5% bottom 50%;
`;

interface SwitchLabelProps {
  sortBy: boolean;
  alphabetical: boolean;
}

const SwitchLabel = styled(AppText)<SwitchLabelProps>`
  font-size: 12px;
  font-weight: 500;
  margin: 8px;
`;

const SearchBoxContainer = styled.div`
  position: relative;
  width: 224px;
`;

const SearchInput = styled.input`
  width: 100%;
  height: 40px;

  border-radius: 4.4px;
  border: solid 0.9px ${theme.PILL_GRAY};
  padding: 8px 8px 8px 30px;

  font-family: ${theme.PRIMARY_FONT};
  background-color: ${theme.WHITE_COLOR};
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  :focus {
    outline: none;
  }
  ::placeholder {
    color: ${theme.NEUTRAL300};
  }
`;

interface IconProps {
  url: string;
  color: string;
  size: string;
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
}

const Icon = styled.div<IconProps>`
  position: absolute;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  top: ${(props) => props.top}px;
  right: ${(props) => props.right}px;
  bottom: ${(props) => props.bottom}px;
  left: ${(props) => props.left}px;
  background-color: ${(props) => props.color};
  -webkit-mask: url(${(props) => props.url}) no-repeat center;
  mask: url(${(props) => props.url}) no-repeat center;
  mask-size: ${(props) => props.size}px;
  transition: background-color 0.2s ease;
`;

const TeamMembersLegend = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 27px;
  height: 34px;
`;
const LegendLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;
const LegendRight = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
`;

const ViewText = styled.span`
  display: flex;
  align-items: center;
  margin-left: 10px;
  font-size: 12px;
  font-weight: 500;
`;
const ViewWrap = styled((props) => <FlexDiv {...props} />)`
  position: relative;
  height: 18px;
  cursor: pointer;
`;

interface ColorCardProps {
  variant?: "primary" | "secondary";
}

const ColorCard = styled.div<ColorCardProps>`
  min-width: 27px;
  text-align: center;
  color: ${(props) => (props.variant === "primary" ? theme.BLACK_COLOR : theme.WHITE_COLOR)};
  background-color: ${(props) => (props.variant === "primary" ? theme.TERTIARY500 : theme.PRIMARY500)};
  border-radius: 4px;

  font-weight: 600;
  font-size: 8px;
  padding: 6px 8px 6px 8px;
`;

const ActionButton = styled((props) => <NewAppButton {...props} />)`
  padding: 0px 24px;
  border-color: ${theme.PRIMARY500};
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
`;

const DisappearingDiv = styled.div`
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  align-items: center;
`;

const TopAlignDiv = styled.div`
  min-height: 400px;
  /* max-height: 600px; */
`;

const OptionsDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const OptionsLeft = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

const OptionsRight = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

const ManageColumns = styled.div`
  display: flex;
  justify-content: right;
  height: 27px;
`;

const slideInAnimation = keyframes`
  0% { margin-left: 0px; width: 0px; }
  100% { margin-left: 0px; width: 400px; }
`;

const DarkDiv = styled.div`
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const SlideInDiv = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 400px;
  min-height: 100vh;
  overflow: hidden;
  animation-name: ${slideInAnimation};
  animation-duration: 700ms;
  background-color: ${theme.WHITE_COLOR};
`;

export { StepUsersV2 };

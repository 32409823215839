import React, { useState, ChangeEvent, useRef, useEffect } from "react";
import styled from "styled-components";
import { theme } from "../../utils/theme";
import { PhoenixInput, PhoenixTextArea, PhoenixAppButton } from "../UI/Phoenix";
import { gql, useQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { appToast } from "../../utils/toast";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useHistory } from "react-router-dom";

const TEST_AI_CALL_REPORT = gql`
  query TestAICallReport($conference_id: String!, $system_prompt: String, $user_prompt: String) {
    TestAICallReport(conference_id: $conference_id, system_prompt: $system_prompt, user_prompt: $user_prompt)
  }
`;

const Container = styled.div`
  padding: 24px;
  background: ${theme.WHITE_COLOR};
  min-height: 100vh;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const ButtonContainer = styled.div`
  width: 140px;
  display: flex;
  > button {
    width: 100%;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  gap: 24px;
  height: calc(100vh - 100px);
`;

const LeftPanel = styled.div`
  flex: 0 0 30%;
  background: ${theme.NEUTRAL100};
  border-radius: 8px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const RightPanel = styled.div`
  flex: 1;
  background: ${theme.NEUTRAL100};
  border-radius: 8px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Title = styled.h1`
  font-family: ${theme.PRIMARY_FONT};
  font-size: 20px;
  font-weight: 600;
  color: ${theme.BLACK_COLOR};
  margin: 0;
`;

const ResponseTitle = styled(Title)`
  font-size: 16px;
`;

const InputLabel = styled.div`
  font-family: ${theme.PRIMARY_FONT};
  font-size: 12px;
  font-weight: 600;
  color: ${theme.BLACK_COLOR};
  margin-bottom: 5px;
`;

const TextAreaWrapper = styled.div`
  position: relative;
  width: 100%;
  min-width: calc(100% - 48px);
`;

const MarkdownContainer = styled.div`
  font-family: ${theme.PRIMARY_FONT};
  overflow-y: auto;
  max-height: calc(100vh - 200px);
  white-space: pre-wrap;
  word-break: break-word;
`;

const DashboardAI: React.FC = () => {
  const { aicoach } = useFlags();
  const history = useHistory();
  const [conferenceId, setConferenceId] = useState("");
  const [systemPrompt, setSystemPrompt] = useState("");
  const [userPrompt, setUserPrompt] = useState("{call_transcript}");
  const [textAreaWidth, setTextAreaWidth] = useState<number | undefined>();

  const leftPanelRef = useRef<HTMLDivElement>(null);
  const systemPromptRef = useRef<HTMLTextAreaElement>(null);
  const userPromptRef = useRef<HTMLTextAreaElement>(null);

  const { data, loading, error, refetch, networkStatus } = useQuery(TEST_AI_CALL_REPORT, {
    variables: {
      conference_id: "74617f0a-e3cc-40a3-930c-7247115e9820",
      system_prompt: "test",
      user_prompt: "test",
    },
    notifyOnNetworkStatusChange: true,
    skip: !aicoach,
  });

  const handleResize = (element: HTMLTextAreaElement) => {
    if (leftPanelRef.current) {
      const minWidth = leftPanelRef.current.clientWidth - 48;
      const newWidth = Math.max(element.offsetWidth, minWidth);
      setTextAreaWidth(newWidth);
    }
  };

  const handleRun = async () => {
    if (!conferenceId) {
      appToast("Please enter a conference ID", { type: "error" });
      return;
    }
    try {
      await refetch({
        conference_id: conferenceId,
        system_prompt: systemPrompt.trim(),
        user_prompt: userPrompt.trim(),
      });
    } catch (error) {
      Sentry.captureException(error);
      appToast("Failed to generate response", { type: "error" });
    }
  };

  useEffect(() => {
    if (!aicoach) {
      history.push("/404");
      return;
    }

    const systemTextArea = systemPromptRef.current;
    const userTextArea = userPromptRef.current;

    if (systemTextArea && userTextArea && leftPanelRef.current) {
      const minWidth = leftPanelRef.current.clientWidth - 48;
      setTextAreaWidth(minWidth);

      const resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          handleResize(entry.target as HTMLTextAreaElement);
        }
      });

      resizeObserver.observe(systemTextArea);
      resizeObserver.observe(userTextArea);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [aicoach]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setConferenceId(e.target.value);
  };

  const handleSystemPromptChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setSystemPrompt(e.target.value);
  };

  const handleUserPromptChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setUserPrompt(e.target.value);
  };

  return (
    <Container>
      <HeaderContainer>
        <Title>Dashboard AI</Title>
        <ButtonContainer>
          <PhoenixAppButton buttonType="primary" variant="brand" onClick={handleRun} disabled={loading}>
            {loading ? "Running..." : "Run"}
          </PhoenixAppButton>
        </ButtonContainer>
      </HeaderContainer>
      <ContentWrapper>
        <LeftPanel ref={leftPanelRef}>
          <div>
            <InputLabel>Conference ID</InputLabel>
            <PhoenixInput value={conferenceId} onChange={handleInputChange} placeholder="Enter conference ID" />
          </div>
          <TextAreaWrapper>
            <InputLabel>System Prompt</InputLabel>
            <PhoenixTextArea
              ref={systemPromptRef}
              value={systemPrompt}
              onChange={handleSystemPromptChange}
              placeholder="Enter system prompt"
              minHeight={120}
              width={textAreaWidth}
              minWidth="100%"
              ableToResize
            />
          </TextAreaWrapper>
          <TextAreaWrapper>
            <InputLabel>User Prompt</InputLabel>
            <PhoenixTextArea
              ref={userPromptRef}
              value={userPrompt}
              onChange={handleUserPromptChange}
              placeholder="Enter user prompt"
              minHeight={120}
              width={textAreaWidth}
              minWidth="100%"
              ableToResize
            />
          </TextAreaWrapper>
          <p>Use &#123;call_transcript&#125; to insert call text.</p>
        </LeftPanel>
        <RightPanel>
          <ResponseTitle>Response</ResponseTitle>
          <MarkdownContainer>
            {data?.TestAICallReport[0]?.content && data.TestAICallReport[0].content}
          </MarkdownContainer>
        </RightPanel>
      </ContentWrapper>
    </Container>
  );
};
export { DashboardAI };
